<template>
  <div class="duty">
    <titles></titles>
    <div class="banner">
      <div class="duty_banner_txt">
        <h2>投身邦芒公益  增进社会福祉</h2>
        <p>未来，邦芒仍会牢记使命，勇往直前</p>
      </div>
      <img src="@/assets/duty_banner.jpg" />
    </div>

    <div class="duty_box">
      <div class="all_box">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide swiper1_slide"
              v-for="(item, index) in dataList"
              :key="index"
            >
              <div class="swiper_left">
                <h3>{{ item.enTitle }}</h3>
                <h4>{{ item.cnTitle }}</h4>
                <h5>{{ item.fTitle }}</h5>
                <div class="swiper_left_p" v-html="item.text"></div>
              </div>
              <div class="swiper_right">
                <div class="swiper-container swiper2">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide swiper2_slide"
                      v-for="(item2, index2) in item.imgList"
                      :key="index2"
                    >
                      <img :src="item2" />
                    </div>
                  </div>
                  <!-- 如果需要分页器 -->
                  <div class="swiper-pagination swiper-pagination2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>

    <foots :type="3"></foots>
  </div>
</template>

<script>
// import img01 from "../assets/duty/01.png";
import img02 from "../assets/duty/02.png";
import img03 from "../assets/duty/03.png";
// import img04 from "../assets/duty/04.png";
import img05 from "../assets/duty/05.png";
import img06 from "../assets/duty/06.png";
import img07 from "../assets/duty/07.png";
import img08 from "../assets/duty/08.png";
import img09 from "../assets/duty/09.png";
import img10 from "../assets/duty/10.png";
import img11 from "../assets/duty/11.png";
import img12 from "../assets/duty/12.png";
import img13 from "../assets/duty/13.png";
import img14 from "../assets/duty/14.png";
import img15 from "../assets/duty/15.png";
import img16 from "../assets/duty/16.png";

import Swiper from "swiper";
import titles from "@/components/titles.vue";
import foots from "@/components/foots.vue";
export default {
  name: "Duty",
  components: {
    titles,
    foots,
  },
  data() {
    return {
      dataList: [
        {
          enTitle: "BANGMANG",
          cnTitle: "奉献爱心 聚力担当",
          fTitle: "邦芒集团组织开展无偿献血公益活动",
          text: "<p>面对血站血型全线告急、临床用血短缺的严峻形势，邦芒集团积极响应政府号召，组织开展“奉献爱心，延绵热血”无偿献血活动。集团员工踊跃报名参与，纷纷贡献自己的绵薄之力。此次活动中，既有初次献血的员工，也有多次献血的员工，大家都怀揣着美好的希冀积极配合医务工作人员展开献血活动，场面秩序井然，温馨和谐。这些爱心人士汇聚在一起，用小爱成就大爱，造福整个社会。</p><p>邦芒始终把人文关怀放在企业文化的重要位置上，社会有难，邦芒支援。邦芒人的快乐也从帮助他人上得以彰显，用一颗颗爱心照亮他人前行的道路。未来，邦芒会坚守初心，在服务社会的路上坚定向前。</p>",
          // imgList: [img01, img02, img03,img04],
          imgList: [img02, img03],
        },
        {
          enTitle: "BANGMANG",
          cnTitle: "公益课堂 传递知识",
          fTitle: "邦芒在企业、学校等机构开展公益课堂",
          text: "<p>邦芒集团开设的公益课堂，为企业管理者提供专业的人力资源管理知识，拓宽管理思维；为员工提供全面的职业技能培训，提升员工的专业技能，培养一技之长；同时为学校的在校学生提供科学合理的职业规划建议，帮助学生从校园过渡到社会，顺畅完成职场人的身份转变。在公益课堂上，邦芒用自身多年的行业经验和丰富的人力资源知识，帮助企业、员工和学生了解行业信息，创造更好的未来。/p><p>邦芒始终认为，一个好的企业一定是能创造出更大社会价值的。因此，邦芒相信，成就他人就是成就自己，坚持用自己的力量帮助社会各行业，不断传递知识，弘扬正能量。</p>",
          imgList: [img05, img06, img07],
        },
{
          enTitle: "BANGMANG",
          cnTitle: "爱与梦想 跨越山海",
          fTitle: "邦芒公益基金守护贵州湄潭留守儿童",
          text: "<p>在贵州湄潭的大山脚下，有一群条件艰苦的学生，他们大多都是留守儿童。面对这些孩子们原生家庭的困顿和生活学习的不便，集团内部228家直营分公司的数千名员工共同参与，为当地的“四点半”学校捐赠电脑、投影仪等教学设备，让孩子们看到更清晰广阔的世界；同时捐赠包含文具、绘画本、彩笔和体育器材的爱心包裹，传递给孩子们满满的爱心与能量。</p><p>邦芒成立公益基金，长期投身于助学公益，传递爱心守护留守儿童，为山区贫困儿童捐款捐物累计500万元；成立邦芒教育基金，大力支持高校人才培养与发展，捐赠累计600万元。</p><p>邦芒始终把社会责任感当做企业的信条，从小业走向大家，邦芒公益基金还将长期持续地投身于助学、助农、助残和环保等各类公益活动中，在社会需要的地方发光发热，传递温暖。</p>",
          imgList: [img08, img09, img10],
        },{
          enTitle: "BANGMANG",
          cnTitle: "网格连心 有爱同行",
          fTitle: "邦芒爱心筑梦行动仪式顺利开启",
          text: "<p>为深入开展“守根脉 保平安 办实事”网络大走访，深化街道暖心行动，6月16日上午，街道在人力资源产业园青年之家，举办“网格连心 有爱同行”当湖街道企业暖心筑梦行动启动仪式。</p><p>邦芒成立公益基金，长期投身于助学公益，传递爱心守护留守儿童，为山区贫困儿童捐款捐物累计500万元；成立邦芒教育基金，大力支持高校人才培养与发展，捐赠累计600万元。</p><p>当湖街道自2019年开始推进暖心行动，通过广泛链接社会资源，搭建奉献爱心、传递真情的平台，为独老孤幼家庭等特殊群体送去爱心，三年多来合计受益人群超过3.5人次。</p><p>在此次网格大走访中，发现一些企业意向加入暖心行动，因此，街道推出企业暖心筑梦行动，鼓励企业除了扶贫济困的输血式帮扶之外，更要依托自身资源，为困难青少年开展造血式帮扶。</p>",
          imgList: [img11, img12, img13],
        },{
          enTitle: "BANGMANG",
          cnTitle: "赋能高校，培育青年",
          fTitle: "邦芒在企业、学校等机构开展公益课堂",
          text: "<p>邦芒高度重视高校的人才培养。为培育新时代积极向上、勇于创新的青年，邦芒承担起社会责任，联结高校，为在校学生提供丰富的校外资源，倡导大学学子充分利用大学时间，提升自我，夯实基础。/p><p>2021年，邦芒受聘成为上海大学首届卓越班级校外导师；2022年，邦芒开办嘉兴学院微课堂，提供高效、精准的人力资源行业信息支持，加速培养应用型高级人力资源管理人才；受聘成为上海财经大学创业学院创业导师，为在校学子提供前沿的就业创业指导。</p><p>邦芒同各大高校积极开展合作，承担校外导师的责任，给予莘莘学子职业培训和职业发展的积极指导，充分发挥校企联动的育人模式，助推学子们的实践能力稳步提升。未来，邦芒始终致力于践行社会责任，为青年办实事，为青年谋未来。</p>",
          imgList: [img14, img15, img16],
        },
      ],
    };
  },
  mounted() {
    this.handler();
    this.smHandler();
    window.scrollTo(0, 0);
  },

  methods: {
    //小swiper
    smHandler() {
      this.$nextTick(() => {
        var mySwiper2 = new Swiper(".swiper2", {
          loop: true,

          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination2",
            clickable: true,
          },
        });
        console.log(mySwiper2);
      });
    },
    //大swiper
    handler() {
      this.$nextTick(() => {
        var mySwiper = new Swiper(".swiper-container", {
          loop: true,

          // 如果需要前进后退按钮
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
        console.log(mySwiper);
      });
    },
  },
};
</script>


<style lang="scss">
.duty_box {
  .swiper-button-prev:after {
    content: "";
    width: 56px;
    height: 19px;
    background: url(../assets/zr_left.png) center center no-repeat;
  }
  .swiper-button-next:after {
    content: "";
    width: 26px;
    height: 18px;
    background: url(../assets/zr_right.png) center center no-repeat;
  }
  .swiper-button-prev {
    left: -30px;
    right: auto;
    width: 56px;
    top: 92%;
  }
  .swiper-button-next {
    left: 60px;
    right: auto;
    width: 26px;
    top: 92%;
  }
}
</style>

<style lang="scss" scoped>
.banner{
  position: relative;
}
.duty_banner_txt{
  width: 100%;
  top: 50%;
  margin-top:-76px;
  position: absolute;
  color: #fff;
  h2{
    margin: 0;
    font-size: 55px;
  }
  p{
    margin: 0;
    margin-top: 50px;
    font-size: 24px;
  }
}
.swiper1_slide {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.duty_box {
  position: relative;
  padding: 100px 0 120px;
}
// .swiper {
//   width: 600px;
//   height: 300px;
// }
.swiper2_slide {
  img {
    width: 100%;
    border-bottom: 7px solid #d70035;
    display: block;
  }
}
.swiper_right {
  width: 56%;
}
.swiper_left {
  background: #f0f0f2;
  width: 44%;
  box-sizing: border-box;
  padding: 35px 50px 0;
  text-align: left;
  h3 {
    font-size: 55px;
    color: #ede8e6;
    margin: 0;
  }
  h4 {
    font-size: 30px;
    color: #333333;
    margin: 0;
  }
  h5 {
    font-size: 16px;
    color: #969696;
    margin: 0;
    font-weight: inherit;
  }
  .swiper_left_p {
    text-align: left;
    font-size: 14px;
    line-height: 26px;
    color: #676767;
    p {
      margin: 40px 0;
    }
  }
}
</style>